import React, { FC } from "react";
import { Pages } from "../../lib/pages";
import styles from "./link.module.scss";
import SingleLink from "./singleLink";

const MultiLinks: FC = () => (
	<div className={styles.shbar}>
		<SingleLink text="O projektu" link={Pages.About} icon="MdInfo" />
		<SingleLink text="Hrát" link={Pages.Game} icon="MdPlayCircleFilled" />
		<SingleLink text="Pro rodiče" link={Pages.PrivacyPolicy} icon="MdPeopleAlt" />
	</div>
);

export default MultiLinks;
