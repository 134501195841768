import type { NextPage } from "next";
import styles from "../styles/index.module.scss";
import { Center } from "../components/basic";
import { Logo } from "../components/images";
import { MainLinks } from "../components/mainlinks";

const Index: NextPage = () => (
	<Center>
		<div>
			<Center>
				<div className={styles.logo}>
					<Logo />
				</div>
			</Center>
			<Center>
				<MainLinks />
			</Center>
		</div>
	</Center>
);

export default Index;
